import * as React from "react";
import axios from "axios";
import Utils from "../../utils/utils";
import { LocalHospitalOutlined, VideocamOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import './Home.css';
import _ from "lodash";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import Spinner from "../../components/spinner/spinner";

const muiSelectStyles = {
    color: '#fff',
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#fff',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#fff',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#fff',
    },
    '& .MuiSvgIcon-root': {
        color: '#fff',
    },

}

const muiLabelStyles = {
    color: '#fff',
    '&.Mui-focused': {
        color: '#fff',
    }
}

const helperText = {
    "Psychiatrist": "A psychiatrist will help you manage your mental health concerns and prescribe medication if necessary.",
    "Couple Therapist": "If you're having relationship problems, couples therapy can help you identify specific issues to work on.",
    "Psychotherapist": "Therapy can help you cope with emotional struggles that are making it difficult for you to function well."
}
export default class Home extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
            referenceCode: localStorage.getItem("referenceCode"),
            filtered_doctors: [],
            all_doctors: [],
            designationList: [],
            filters: { 'designation': 'All', 'location': 'All' }
        };
    }

    getConsultants() {
        this.setState({ ...this.state, 'isLoading': true });
        axios.get(
            `${Utils.PRIISM_API_ENDPOINT}/appt_bookings/doctorlistbyhecode/${this.state.referenceCode}/`
        ).then((res) => {
            let consultants = res.data || [];
            if (res.data) {
                // consultants = consultants.concat(res.data.psychiatrist || []);
                // consultants = consultants.concat(res.data.socialworker || []);
            }
            this.setFilteredData(consultants);
        });
    }

    setFilteredData(consultants) {
        let dataList = this.getDistinctDesignations(consultants);
        let filteredDoctors = [];
        let keys = _.keys(this.state?.filters);
        if (keys.length > 0) {
            consultants.forEach(doctor => {
                let flag = true;
                if (this.state?.filters?.designation !== 'All' && _.has(this.state.filters, 'designation') && this.state?.filters?.designation && !doctor.designation.includes(this.state?.filters?.designation)) {
                    flag = false;
                }
                if (this.state?.filters?.location !== 'All' && _.has(this.state.filters, 'location') && this.state?.filters?.location && doctor?.location?.city !== this.state?.filters?.location) {
                    flag = false;
                }
                if (flag) {
                    filteredDoctors.push(doctor);
                }
            });
        } else {
            filteredDoctors = consultants;
        }

        this.setState({
            ...this.state, "all_doctors": consultants,
            'filtered_doctors': filteredDoctors,
            'isLoading': false,
            "designationList": dataList.designations,
            "locationList": dataList.locations
        });
    }

    getDistinctDesignations(consultants) {
        let designations = [], locations = [];
        consultants.forEach(element => {
            if (element?.designation && element.designation?.length > 0)
                designations = [...designations, ...element.designation];
            if (element?.location?.city)
                locations = [...locations, element.location.city];
        });
        // removeing duplicate designations and removeing 'Counsellor' designation from list
        designations = _.remove(_.uniq(designations), function (item) {
            return item !== 'Counsellor';
        });
        return { designations: designations, locations: _.uniq(locations).sort() };
    }

    componentDidMount(): void {
        this.getConsultants();
    }

    handleChange(e, name) {
        this.state.filters[name] = e.target.value;
        this.setState({ ...this.state, "filters": { ...this.state.filters, [name]: e.target.value }, 'isLoading': true });
        this.setFilteredData(this.state.all_doctors)
    }

    handleDesignationChange(e) {
        this.state.filters[e.target.name] = e.target.value;
        this.setState({ ...this.state, "filters": { ...this.state.filters, [e.target.name]: e.target.value }, 'isLoading': true });
        this.setFilteredData(this.state.all_doctors)
    }


    gotoCreateAppointment(userId): void {
        window.location.href = "/book-an-appointment/" + this.state.referenceCode + "/" + userId;
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isLoading && <Spinner />}
                <Box p={2}
                    display="flex" alignItems="center" className={'home-box'}>
                    <span className="home-appt-heading">
                        Book your appointment to consult our experts Online/In-person
                    </span>
                    <h4 className="filter-heading">Who Do You Want To Consult?</h4>
                    {/* <ToggleButtonGroup
                        // color="primary"
                        value={this.state.filters?.designation}
                        exclusive
                        onChange={(e) => { this.handleChange(e, 'designation'); }}
                        aria-label="designation"
                        className="toggle-buttons"
                    >
                        {this.state.designationList?.map((designation, index) => (
                            <ToggleButton key={designation}
                                className="designation-filter-btns" value={designation}
                                style={{ borderRadius: ((index) === 0) ? (this.state.designationList.length - 1 === index) ? '2rem' : '2rem 0 0 2rem' : (this.state.designationList.length - 1 === index) ? '0 2rem 2rem 0' : '0 0 0 0', borderRight: '0px !important' }}>
                                {designation}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup> */}

                    <FormControl sx={{ m: 1, minWidth: 250 }}>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={muiLabelStyles}>Choose Designation</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            name='designation'
                            value={this.state.filters?.designation ?? ""}
                            onChange={(e) => this.handleDesignationChange(e)}
                            autoWidth
                            label="Choose Designation"
                            sx={muiSelectStyles}
                        >
                            <MenuItem value={'All'} >All</MenuItem>
                            {this.state?.designationList?.map((element) => (
                                <MenuItem value={element} key={element}>{element}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {_.has(helperText, this.state.filters?.designation) &&
                        <FormHelperText style={{ color: '#f9e643', fontSize: '1rem' }}>
                            {helperText?.[this.state.filters?.designation]}
                        </FormHelperText>}
                    {this.state.locationList?.length > 0 &&
                        <React.Fragment>
                            <h4 className="filter-heading">Choose City - For an In-person session?</h4>
                            <div className="toggle-buttons-wrapper">
                                <ToggleButtonGroup
                                    // color="primary"
                                    value={this.state.filters?.location}
                                    exclusive
                                    onChange={(e) => { this.handleChange(e, 'location'); }}
                                    aria-label="location"
                                    className="toggle-buttons"
                                >
                                    <ToggleButton className="designation-filter-btns" value={'All'}
                                        style={{ borderRadius: '2rem 0 0 2rem', borderRight: '0px !important' }}>
                                        All
                                    </ToggleButton>
                                    {this.state.locationList?.map((location, index) => (
                                        <ToggleButton key={location}
                                            className="designation-filter-btns" value={location}
                                            style={{
                                                borderRadius: (this.state.locationList.length - 1 === index) ? '0 2rem 2rem 0' : '0 0 0 0', borderRight: '0px !important'
                                            }}>
                                            {location}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </div>
                            <div className="location-dropdown">
                                <FormControl sx={{ m: 1, minWidth: 250 }}>
                                    <InputLabel id="demo-simple-select-autowidth-label" sx={muiLabelStyles}>Choose City</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        autoWidth
                                        label="Choose Designation"
                                        name='location'
                                        value={this.state.filters?.location}
                                        onChange={(e) => { this.handleChange(e, 'location') }}
                                        sx={muiSelectStyles}
                                    >
                                        <MenuItem value={'All'}>All</MenuItem>
                                        {this.state.locationList?.map((location) => (
                                            <MenuItem value={location} key={location}>{location}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </React.Fragment>
                    }
                </Box>
                <div className="provider-list row">
                    {this.state?.filtered_doctors.length > 0 && this.state?.filtered_doctors.map((doctor, index) => (
                        <div className="profile-card" key={doctor.userId}>
                            <div className="provider-details">
                                <div className="provider-image-container">
                                    <img className="profile-image"
                                        src={doctor?.profilePicture ? (Utils.PRIISM_WEB_URL + doctor['profilePicture']) : "/images/default_user.png"}
                                        alt="Provider Photo"
                                        onClick={
                                            () => { window.location.href = "/profile/" + this.state.referenceCode + "/" + doctor['userId'] }
                                        }
                                    />
                                    <div className="profile-view"><a style={{ color: 'white', textDecoration: 'none' }} href={"/profile/" + this.state.referenceCode + "/" + doctor['userId']}> View Profile</a></div>
                                </div>
                                <div className="provider-info">
                                    <span className="profile-name">
                                        {doctor?.firstName}{(doctor?.lastName) ? ' ' + doctor?.lastName : ''}
                                    </span>
                                    {doctor?.designation && doctor.designation.length > 0 && <div className="languages-container">
                                        {/* <span className="title-text">Designation:</span> */}
                                        <span className="languages">
                                            {doctor.designation.join(', ')}
                                        </span>
                                    </div>
                                    }
                                    {doctor['experienceInYears'] && <div>
                                        <span className="experience">
                                            <strong>{doctor['experienceInYears']}+ </strong>years of experience
                                        </span>
                                    </div>}

                                    <div>Starts @<b><span className="fee">&nbsp;&#x20B9;{doctor?.['billAmtStartsAt']?.['amount']} for {doctor?.['billAmtStartsAt']?.['duration']} mins</span></b></div>
                                    <div className="details hide-scroll-bar">
                                        {doctor['expertise']?.length > 0 && <div className="expertise-details hide-scroll-bar">
                                            <span className="title-text">
                                                Expertise:
                                            </span>
                                            <div className="expertise-list hide-scroll-bar" style={{ marginBottom: '6px' }}>
                                                {doctor['expertise'].map((expertise) => (
                                                    <span className="expertise" key={expertise}>
                                                        {expertise}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>}
                                        {doctor['languagesISpeak']?.length > 0 && <div className="languages-container">
                                            <span className="title-text">Speaks:</span>
                                            <span className="languages">
                                                &nbsp;{doctor['languagesISpeak'].join(', ')}
                                            </span>
                                        </div>}
                                        <span className="title-text">
                                            Session Mode: &nbsp;
                                            <b>
                                                <span className="session-modes">
                                                    {doctor?.['sessionTypes'] && doctor['sessionTypes'].length > 0 && doctor['sessionTypes'].includes('Online') &&
                                                        <span>
                                                            <VideocamOutlined style={{ marginBottom: '-5px' }} fontSize="small" />
                                                            &nbsp;Online,&nbsp;&nbsp;
                                                        </span>
                                                    }
                                                    {/* <DomainAddOutlinedIcon style={{ marginBottom: '-4px' }} fontSize="small" /> */}
                                                    {doctor?.['sessionTypes'] && doctor['sessionTypes'].length > 0 && doctor['sessionTypes'].includes('In-person') &&
                                                        <span>
                                                            <LocalHospitalOutlined style={{ marginBottom: '-5px' }} fontSize="small" />
                                                            &nbsp;In-Person
                                                            {doctor['location']['city'] && <span className="city">
                                                                &nbsp;(
                                                                {/* <LocationOnOutlinedIcon style={{ marginBottom: '-3px' }} fontSize="small" /> */}
                                                                {doctor['location']['city'].trim()})
                                                            </span>}
                                                        </span>
                                                    }
                                                </span>
                                            </b>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="provider-book-container">
                                <button color="#fff"
                                    className="provider-book-button"
                                    onClick={() => { this.gotoCreateAppointment(doctor['userId']) }}
                                >
                                    Book Appointment
                                </button>
                            </div>
                        </div>
                    ))}
                    {
                        this.state?.filtered_doctors?.length <= 0 &&
                        <div>
                            {this.state.filters?.designation != 'All' &&
                                <h3>
                                    {this.state.filters?.designation} not available
                                    {this.state.filters?.location != 'All' &&
                                        <span> on {this.state.filters?.location} Location</span>
                                    }
                                    .
                                </h3>
                            }
                        </div>
                    }
                </div>
            </React.Fragment >
        );
    }
}
