import * as React from "react";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Alert, Avatar, ListItemAvatar, Stack } from "@mui/material";
import {
  PlusSquareFill,
  Hospital,
  PersonCircle,
  CalendarPlusFill,
  PatchCheckFill,
} from "react-bootstrap-icons";
import Utils from "../../../utils/utils";
import moment from "moment";
import axios from "axios";
import _ from "lodash";
import { UserContext } from "../../../components/layout/Layout";

export default class SummaryDetails extends React.Component<any, any> {
  static contextType = UserContext;
  constructor(props: any) {
    super(props);
  }

  componentDidMount(): void {
    if (_.isEmpty(this.context["patient_no"])) {
      const patientDetails = _.cloneDeep(this.context);
      patientDetails["patient_no"] =
        this.props.appointmentDetails.patient.patientNo;
      patientDetails["patient_id"] =
        this.props.appointmentDetails.patient.patientId;
      axios
        .put(`${Utils.PRIISM_APPT_API_ENDPOINT}/update`, patientDetails)
        .then((res) => {
          localStorage.setItem("patientDetails", JSON.stringify(res.data));
        });
    }
  }

  render() {
    const patientName =
      this.context["first_name"] + " " + this.context["last_name"];
    const locationData = this.props.locations?.find((location: any) => {
      return location.locId === this.props.centre;
    });
    const consultantData = this.props.consultants?.find((consultant: any) => {
      return consultant.userId === this.props.consultant;
    });
    const consultantName =
      consultantData?.firstName + " " + (consultantData?.lastName || "");
    const appointmentType =
      this.props.appointmentType === "TELE" ? "Online" : "Personal visit";
    const consultationType =
      this.props.consultationType === "FIRST"
        ? "First consultation / Therapy session"
        : "Followup consultation";

    return (
      <React.Fragment>
        <Alert severity="success" sx={{ mb: 2 }}>
          <Typography variant="h6">
            {"Appointment booked successfully"}
          </Typography>
        </Alert>
        <Alert severity="info">
          <Typography variant="h6">
            {"Please check your registered email for appointment confirmation"}
          </Typography>
        </Alert>
        <Stack sx={{ mt: 1, mb: 2, ml: 2 }}>
          <ListItem sx={{ pl: 0 }}>
            <ListItemAvatar sx={{ minWidth: "48px" }}>
              <PersonCircle color="#1976d2" size={30} />
            </ListItemAvatar>
            <ListItemText
              primary={consultantName || ""}
              secondary={_.capitalize(consultantData?.role) || ""}
            />
          </ListItem>
          <ListItem sx={{ pl: 0 }}>
            <ListItemAvatar sx={{ minWidth: "48px" }}>
              <PlusSquareFill color="#1976d2" size={30} />
            </ListItemAvatar>
            <ListItemText primary={appointmentType} />
          </ListItem>
          {locationData && (
            <ListItem sx={{ pl: 0 }}>
              <ListItemAvatar sx={{ minWidth: "48px" }}>
                <Hospital color="#1976d2" size={30} />
              </ListItemAvatar>
              <ListItemText primary={locationData?.name} />
            </ListItem>
          )}
          <ListItem sx={{ pl: 0 }}>
            <ListItemAvatar sx={{ minWidth: "48px" }}>
              <PatchCheckFill color="#1976d2" size={30} />
            </ListItemAvatar>
            <ListItemText primary={consultationType} />
          </ListItem>
          <ListItem sx={{ pl: 1, marginLeft: "-8px" }}>
            <ListItemAvatar sx={{ minWidth: "48px" }}>
              <CalendarPlusFill color="#1976d2" size={30} />
            </ListItemAvatar>
            <ListItemText
              primary={moment(this.props.appointmentDetails.start).format(
                "DD/MM/YYYY"
              )}
              secondary={moment(this.props.appointmentDetails.start).format(
                "HH:mm"
              )}
            />
          </ListItem>
          <ListItem sx={{ pl: 0 }}>
            <ListItemAvatar>
              <Avatar {...Utils.stringAvatar(patientName)} />
            </ListItemAvatar>
            <ListItemText
              primary={patientName}
              secondary={this.context["patient_no"]}
            />
          </ListItem>
        </Stack>
      </React.Fragment>
    );
  }
}
