import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import { mainListItems } from "./menu-items";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import PendingIcon from "@mui/icons-material/Pending";
import {
	ListItem,
	ListItemAvatar,
	ListItemText,
	Stack,
	Tooltip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { PersonCircle } from "react-bootstrap-icons";
import { Session } from "../session/Session";
import LogOutService from "../../services/LogOutService";
import { Home, Login } from "@mui/icons-material";
import * as SharedHelpers from "./../../utils/SharedHelpers";

const drawerWidth: number = 240;
type Props = { children: any };
type DashboardState = {
	open: boolean;
	anchorEl: HTMLElement | null;
	isConsentModalOpen: boolean;
};
interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	"& .MuiDrawer-paper": {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: "border-box",
		...(!open && {
			overflowX: "hidden",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

const mdTheme = createTheme();
const patientDetailsStore = JSON.parse(
	localStorage.getItem("patientDetails") || null
);
const referenceCode = localStorage.getItem("referenceCode") || null;
export const UserContext = React.createContext(patientDetailsStore);

export default class Layout extends React.Component<Props, DashboardState> {
	constructor(props: any) {
		super(props);
		this.state = {
			open: false,
			isConsentModalOpen: false,
			anchorEl: null,
		};
		this.closeConsentModal();
		this.consentModal();
		this.handleMenuClose();
	}

	closeConsentModal() {
		this.setState({ ...this.state, isConsentModalOpen: false });
	}

	consentModal = () => {
		return (
			<Dialog
				open={this.state.isConsentModalOpen}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth={true}
				maxWidth={"lg"}
			>
				<DialogTitle id="alert-dialog-title" align="center">
					{"CONSENT FOR CONSULTATION/THERAPY"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{SharedHelpers.CONSENT}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={() => this.closeConsentModal()}>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	handleMenuClose = () => {
		this.setState({ anchorEl: null });
	};

	render() {
		let pageHeader = "";
		if (location.pathname === "/") {
			pageHeader = "Dashboard";
		} else if (
			location.pathname === "/book-an-appointment" ||
			location.pathname === "/book-an-appointment/:heCode"
		) {
			pageHeader = "Book an appointment";
		} else if (location.pathname === "/reschedule-an-appointment") {
			pageHeader = "Reschedule an appointment";
		}
		return (
			<UserContext.Provider value={patientDetailsStore}>
				{this.consentModal()}
				<ThemeProvider theme={mdTheme}>
					<Box sx={{ display: "flex" }}>
						<CssBaseline />
						<AppBar position="absolute">
							<Toolbar>
								{patientDetailsStore?.patient_no && (
									<IconButton
										size="large"
										edge="start"
										color="inherit"
										aria-label="menu"
										sx={{ mr: 2 }}
										onClick={() => {
											this.setState({ open: !this.state.open });
										}}
									>
										<MenuIcon />
									</IconButton>
								)}
								<img
									src={"/images/Manoshanti-logo.png"}
									alt="Manoshanti Logo"
									width={170}
									height={55}
									style={{ marginLeft: "-12px" }}
								/>
								{!patientDetailsStore?.patient_no && (
									<IconButton
										size="small"
										edge="start"
										color="inherit"
										sx={{ mr: 2 }}
										onClick={() => {
											window.location.href = "/home/" + referenceCode;
										}}
									>
										Home
									</IconButton>
								)}

								<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
									{pageHeader}
								</Typography>
								<IconButton
									className="hide-in-mobile"
									size="small"
									edge="start"
									color="inherit"
									sx={{ mr: 2 }}
									onClick={() => {
										window.open("https://manoshanti.com/contact-us/", "_blank");
									}}
								>
									Contact Us
								</IconButton>
								{patientDetailsStore?.id ? (
									<Tooltip title="Sign out" className="hide-in-mobile">
										<IconButton
											size="small"
											color="inherit"
											aria-label="menu"
											sx={{ mr: 2 }}
											onClick={() => {
												LogOutService.logOut();
											}}
										>
											Sign Out&nbsp;
											<LogoutIcon />
										</IconButton>
									</Tooltip>
								) : (
									<Tooltip title="Sign out" className="hide-in-mobile">
										<IconButton
											size="small"
											color="inherit"
											aria-label="menu"
											sx={{ mr: 2 }}
											onClick={() => {
												window.location.href = "/sign-in/" + referenceCode;
											}}
										>
											<Login />
											&nbsp;Sign In
										</IconButton>
									</Tooltip>
								)}
								<IconButton
									className="hide-in-bigscreens"
									size="large"
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={(event) => {
										this.setState({ anchorEl: event.currentTarget });
									}}
									color="inherit"
								>
									<PendingIcon />
								</IconButton>
								<Menu
									id="menu-appbar"
									anchorEl={this.state.anchorEl}
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									keepMounted
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									open={Boolean(this.state.anchorEl)}
									onClose={this.handleMenuClose}
								>
									<MenuItem
										onClick={() =>
											window.open(
												"https://manoshanti.com/contact-us/",
												"_blank"
											)
										}
									>
										Contact Us
									</MenuItem>
									{patientDetailsStore?.id ? (
										<MenuItem
											onClick={() => {
												LogOutService.logOut();
											}}
										>
											Sign Out <LogoutIcon sx={{ ml: 1 }} />
										</MenuItem>
									) : (
										<MenuItem
											onClick={() => {
												window.location.href = "/sign-in/" + referenceCode;
											}}
										>
											Sign In <Login sx={{ ml: 1 }} />
										</MenuItem>
									)}
								</Menu>
							</Toolbar>
						</AppBar>
						{patientDetailsStore?.patient_no && (
							<Drawer variant="permanent" open={this.state.open}>
								<Stack sx={{ mt: 9 }} className="stack-list">
									<ListItem sx={{ pl: 2.2 }}>
										<ListItemAvatar sx={{ minWidth: "48px", pr: 2 }}>
											<PersonCircle color="#1976d2" size={40} />
										</ListItemAvatar>
										<ListItemText
											primary={patientDetailsStore?.first_name ?? ""}
											secondary={patientDetailsStore?.patient_no ?? ""}
										/>
									</ListItem>
									<Divider />
									<List component="nav">{mainListItems}</List>
								</Stack>
							</Drawer>
						)}
						<Box
							component="main"
							sx={{
								backgroundColor: (theme) =>
									theme.palette.mode === "light"
										? theme.palette.grey[100]
										: theme.palette.grey[900],
								flexGrow: 1,
								height: "100vh",
								overflow: "auto",
							}}
						>
							<Toolbar />
							<Container>
								{this.props.children}
								<Session />
							</Container>
						</Box>

						<AppBar position="absolute" sx={{ top: "auto", bottom: 0 }}>
							<Grid
								container
								style={{ padding: "0px 20px" }}
								direction="row"
								justifyContent="space-around"
								alignItems="center"
							>
								<Grid item>
									<span>Copyright © 2024 - Manoshanti All Rights Reserved</span>
								</Grid>
								<Grid item>
									<Button
										variant="text"
										onClick={() => {
											this.setState({
												...this.state,
												isConsentModalOpen: true,
											});
										}}
									>
										Terms and Conditions
									</Button>
								</Grid>
							</Grid>
						</AppBar>
					</Box>
				</ThemeProvider>
			</UserContext.Provider>
		);
	}
}
