import * as React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";
import {
  Alert,
  Avatar,
  Button,
  Collapse,
  Container,
  Drawer,
  FormLabel,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./CalendarDetails.css";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers";
import Utils from "../../../utils/utils";
import {
  Hospital,
  PatchCheckFill,
  PersonCircle,
  PlusSquareFill,
  Save,
  XCircle,
} from "react-bootstrap-icons";
import _ from "lodash";
import Spinner from "../../../components/spinner/spinner";
import { UserContext } from "../../../components/layout/Layout";

const colorHoliday = "#F6E4E0";
const colorExpiredDate = "#f57369";
const colorSlotUnavailable = "#DEBDB6";
const colorSlotAvailable = "#a7dbc9";
const localizer = momentLocalizer(moment);

export default class CalendarDetails extends React.Component<any, any> {
  static contextType = UserContext;
  consultantData = this.props.consultants?.find((consultant: any) => {
    return consultant.userId === this.props.consultant;
  });
  consultantTimings =
    this.props.appointmentType === "OP"
      ? this.consultantData.opTimings
      : this.consultantData.teleTimings;

  constructor(props: any) {
    super(props);
    this.state = {
      addAppointmentDrawer: false,
      description: "",
      startDateTime: "",
      endDateTime: "",
      isHoliday: false,
      isExpiredDate: false,
      isExpiredSlot: false,
      isSlotAvailable: true,
      eventList: [],
      isLoading: false,
      saveError: false,
      isValidDateTimeSlot: true,
    };
  }

  onSelectSlot = (value: any) => {
    const selectedDate = new Date(value.start);

    if (this.checkDateIsHoliday(selectedDate)) {
      this.setState({ isHoliday: true });
      setTimeout(() => {
        this.setState({ isHoliday: false });
      }, 2000);
    } else if (moment(selectedDate).isBefore()) {
      this.setState({ isExpiredDate: true });
      setTimeout(() => {
        this.setState({ isExpiredDate: false });
      }, 2000);
    } else {
      const availablity =
        this.consultantTimings[moment(selectedDate).format("ddd")];
      let calendarTime = parseInt(moment(selectedDate).format("HHmm"));
      const amStart = parseInt(availablity.amStart.split(":").join(""));
      const amEnd = parseInt(availablity.amEnd.split(":").join(""));
      const pmStart = parseInt(availablity.pmStart.split(":").join(""));
      const pmEnd = parseInt(availablity.pmEnd.split(":").join(""));

      // If Slot clicked from Month mode, set start time as availability start time
      if (!value.hasOwnProperty("resourceId")) {
        calendarTime = amStart;
        const startTime = availablity.amStart.split(":");
        value.start = moment(value.start)
          .set("hour", startTime[0])
          .set("minute", startTime[1]);
      }

      if (
        (calendarTime >= amStart && calendarTime <= amEnd) ||
        (calendarTime >= pmStart && calendarTime <= pmEnd)
      ) {
        this.setState({
          isValidDateTimeSlot: true,
          addAppointmentDrawer: true,
          startDateTime: value.start,
          endDateTime: value.end,
        });
      } else {
        this.setState({ isSlotAvailable: false });
        setTimeout(() => {
          this.setState({ isSlotAvailable: true });
        }, 2000);
      }
    }
  };

  bookAnAppointment() {
    this.setState({ isLoading: true });
    const details = _.cloneDeep(this.context);
    details["user_id"] = this.props.consultant;
    details["start"] = this.state.startDateTime;
    details["end"] = this.state.endDateTime;
    details["startTime"] = moment(this.state.startDateTime).format("HH:mm");
    details["endTime"] = moment(this.state.endDateTime).format("HH:mm");
    axios
      .post(
        `${Utils.PRIISM_API_ENDPOINT}/appt_bookings/patientappt/${this.context["heid"]}/${this.props.appointmentType}/`,
        details
      )
      .then((res) => {
        this.props.onBookingAppointmentSuccess(res.data);
        this.setState({ addAppointmentDrawer: false, isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false, saveError: true });
        console.error(error);
      });
  }

  componentDidMount(): void {
    axios
      .get(
        `${Utils.PRIISM_API_ENDPOINT}/appt_bookings/patientapptlist/${this.context["heid"]}/${this.props.consultant}/${this.props.appointmentType}/`
      )
      .then((res) => {
        const appointments = [];
        res?.data?.forEach((element) => {
          if (element.patient?.patientId === this.context["patient_id"]) {
            const appointment = {
              title: `${this.context["first_name"]}`,
              allDay: false,
              start: moment(element.start).toDate(),
              end: moment(element.end).toDate(),
            };
            appointments.push(appointment);
          }
        });
        this.setState({ eventList: appointments });
        this.props.setOutPatientList(res.data);
      });
  }

  checkDateIsHoliday(date: Date) {
    let isHoliday = false;
    if (_.isEmpty(this.consultantTimings)) {
      isHoliday = true;
    } else {
      let daySlots = {};
      if (date.getDay() === 0) {
        daySlots = this.consultantTimings.Sun;
      } else if (date.getDay() === 1) {
        daySlots = this.consultantTimings.Mon;
      } else if (date.getDay() === 2) {
        daySlots = this.consultantTimings.Tue;
      } else if (date.getDay() === 3) {
        daySlots = this.consultantTimings.Wed;
      } else if (date.getDay() === 4) {
        daySlots = this.consultantTimings.Thu;
      } else if (date.getDay() === 5) {
        daySlots = this.consultantTimings.Fri;
      } else {
        daySlots = this.consultantTimings.Sat;
      }
      isHoliday = Object.values(daySlots).every((x) => x === null || x === "");
    }
    const currentDate = moment(date).format("DD/MMM/YYYY");
    if (
      this.consultantData.holidayOne !== null &&
      this.consultantData.holidayOne === currentDate
    ) {
      isHoliday = true;
    } else if (
      this.consultantData.holidayTwo !== null &&
      this.consultantData.holidayTwo === currentDate
    ) {
      isHoliday = true;
    } else if (
      this.consultantData.holidayThree !== null &&
      this.consultantData.holidayThree === currentDate
    ) {
      isHoliday = true;
    } else if (
      this.consultantData.holidayFour !== null &&
      this.consultantData.holidayFour === currentDate
    ) {
      isHoliday = true;
    } else if (
      this.consultantData.holidayFive !== null &&
      this.consultantData.holidayFive === currentDate
    ) {
      isHoliday = true;
    }
    return isHoliday;
  }

  handleDateTimePickerValidation(startDateTime, sessionDuration) {
    this.setState({
      startDateTime,
      endDateTime: moment(startDateTime).add(sessionDuration, "minutes"),
    });
    let isValidDateTimeSlot = true;
    const isHoliday = this.checkDateIsHoliday(startDateTime);
    if (isHoliday) {
      isValidDateTimeSlot = false;
    } else if (moment(startDateTime).isBefore()) {
      isValidDateTimeSlot = false;
    } else {
      const availablity =
        this.consultantTimings[moment(startDateTime).format("ddd")];
      let calendarTime = parseInt(moment(startDateTime).format("HHmm"));
      const amStart = parseInt(availablity.amStart.split(":").join(""));
      const amEnd = parseInt(availablity.amEnd.split(":").join(""));
      const pmStart = parseInt(availablity.pmStart.split(":").join(""));
      const pmEnd = parseInt(availablity.pmEnd.split(":").join(""));

      isValidDateTimeSlot =
        (calendarTime >= amStart && calendarTime <= amEnd) ||
        (calendarTime >= pmStart && calendarTime <= pmEnd);
    }
    this.setState({
      isValidDateTimeSlot,
    });
  }

  render() {
    const patientName =
      this.context["first_name"] + " " + this.context["last_name"];
    const locationData = this.props.locations?.find((location: any) => {
      return location.locId === this.props.centre;
    });

    const sessionDuration = this.consultantData.sessionDuration
      ? parseInt(this.consultantData.sessionDuration)
      : 30;

    const consultantName =
      this.consultantData?.firstName +
      " " +
      (this.consultantData?.lastName || "");
    const appointmentType =
      this.props.appointmentType === "TELE" ? "Online" : "Personal visit";
    const consultationType =
      this.props.consultationType === "FIRST"
        ? "First consultation / Therapy session"
        : "Followup consultation";

    const customDayPropGetter = (date: Date) => {
      const isHoliday = this.checkDateIsHoliday(date);

      if (isHoliday) {
        return {
          className: "holiday-day",
          style: {
            backgroundColor: colorHoliday,
            border: "solid 1px #D14426",
            background:
              "repeating-linear-gradient(-45deg,transparent,transparent 4px,transparent 1px,#e8b2ac 7px),linear-gradient(to bottom, transparent, transparent)",
          },
        };
      } else if (moment(date).isBefore()) {
        return {
          className: "previous-day",
          style: {
            backgroundColor: colorExpiredDate,
            border: "solid 1px #FFF",
          },
        };
      }
    };

    // Time slot wrapper
    const timeSlotWrapper = (props: { children }) => {
      let backgroundColor = "";
      if (props["value"]) {
        const momentValue = moment(props["value"]);
        const day = momentValue.format("ddd");
        const availablity = this.consultantTimings[day];
        const isHoliday = Object.values(availablity).every(
          (x) => x === null || x === ""
        );

        // Holiday
        if (isHoliday) {
          backgroundColor = colorHoliday;
          return React.cloneElement(React.Children.only(props.children), {
            style: {
              backgroundColor: backgroundColor,
              background:
                "repeating-linear-gradient(-45deg,transparent,transparent 4px,transparent 1px,#e8b2ac 7px),linear-gradient(to bottom, transparent, transparent)",
            },
          });
        } else if (momentValue.isBefore()) {
          // Previous day
          backgroundColor = colorExpiredDate;
        } else {
          const calendarTime = parseInt(momentValue.format("HHmm"));
          const amStart = parseInt(availablity.amStart.split(":").join(""));
          const amEnd = parseInt(availablity.amEnd.split(":").join(""));
          const pmStart = parseInt(availablity.pmStart.split(":").join(""));
          const pmEnd = parseInt(availablity.pmEnd.split(":").join(""));
          if (
            (calendarTime >= amStart && calendarTime <= amEnd) ||
            (calendarTime >= pmStart && calendarTime <= pmEnd)
          ) {
            backgroundColor = colorSlotAvailable;
          } else {
            backgroundColor = colorSlotUnavailable;
          }
        }
      }

      return React.cloneElement(React.Children.only(props.children), {
        style: {
          backgroundColor: backgroundColor,
        },
      });
    };
    return (
      <React.Fragment>
        <Collapse in={this.state.isHoliday}>
          <Alert sx={{ mb: 4 }} severity="error">
            Selected day is an holiday.
          </Alert>
        </Collapse>
        <Collapse in={this.state.isExpiredDate}>
          <Alert sx={{ mb: 4 }} severity="error">
            Selected day is expired.
          </Alert>
        </Collapse>
        <Collapse in={this.state.isExpiredSlot}>
          <Alert sx={{ mb: 4 }} severity="error">
            Selected slot is expired.
          </Alert>
        </Collapse>
        <Collapse in={!this.state.isSlotAvailable}>
          <Alert sx={{ mb: 4 }} severity="error">
            Selected slot is not available.
          </Alert>
        </Collapse>
        <Calendar
          onSelectSlot={this.onSelectSlot}
          selectable={true}
          events={this.state.eventList}
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          dayPropGetter={customDayPropGetter}
          components={{
            timeSlotWrapper: timeSlotWrapper,
          }}
          views={["month", "week", "day"]}
        />
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item lg={2.5}>
            <div className="color-container">
              <div className="box available-slot"></div>
              <span className="color-palette-title">Available slot</span>
            </div>
          </Grid>
          <Grid item lg={2.5}>
            <div className="color-container">
              <div className="box booked-slot"></div>
              <span className="color-palette-title">Booked slot</span>
            </div>
          </Grid>
          <Grid item lg={3}>
            <div className="color-container">
              <div className="box not-available-slot"></div>
              <span className="color-palette-title">Not available slot</span>
            </div>
          </Grid>
          <Grid item lg={2}>
            <div className="color-container">
              <div className="box holiday"></div>
              <span className="color-palette-title">Holiday</span>
            </div>
          </Grid>
          <Grid item lg={2}>
            <div className="color-container">
              <div className="box expired-date"></div>
              <span className="color-palette-title">Past date</span>
            </div>
          </Grid>
        </Grid>
        <Drawer
          PaperProps={{
            sx: {
              width: "30%",
              marginTop: "64px",
              height: "90%",
              overflow: "scroll",
            },
          }}
          anchor="right"
          open={this.state.addAppointmentDrawer}
          onClose={() => {
            this.setState({ addAppointmentDrawer: false });
          }}
        >
          {this.state.isLoading && <Spinner />}
          <Container>
            <Stack sx={{ mt: 1, mb: 2 }}>
              {this.state.saveError && (
                <Alert sx={{ mb: 4 }} severity="error">
                  Error in saving the date. Please try again.
                </Alert>
              )}
              <ListItem sx={{ pl: 0 }}>
                <ListItemAvatar sx={{ minWidth: "48px" }}>
                  <PersonCircle color="#1976d2" size={30} />
                </ListItemAvatar>
                <ListItemText
                  primary={consultantName || ""}
                  secondary={_.capitalize(this.consultantData?.role) || ""}
                />
              </ListItem>
              <ListItem sx={{ pl: 0 }}>
                <ListItemAvatar sx={{ minWidth: "48px" }}>
                  <PlusSquareFill color="#1976d2" size={30} />
                </ListItemAvatar>
                <ListItemText primary={appointmentType} />
              </ListItem>
              {locationData && (
                <ListItem sx={{ pl: 0 }}>
                  <ListItemAvatar sx={{ minWidth: "48px" }}>
                    <Hospital color="#1976d2" size={30} />
                  </ListItemAvatar>
                  <ListItemText primary={locationData?.name} />
                </ListItem>
              )}
              <ListItem sx={{ pl: 0 }}>
                <ListItemAvatar sx={{ minWidth: "48px" }}>
                  <PatchCheckFill color="#1976d2" size={30} />
                </ListItemAvatar>
                <ListItemText primary={consultationType} />
              </ListItem>
              <ListItem sx={{ pl: 0 }}>
                <ListItemAvatar>
                  <Avatar {...Utils.stringAvatar(patientName)} />
                </ListItemAvatar>
                <ListItemText
                  primary={patientName}
                  secondary={this.context["patient_no"]}
                />
              </ListItem>
            </Stack>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container spacing={1}>
                <Grid item lg={7}>
                  <DesktopDatePicker
                    label="Appointment date"
                    inputFormat="dd/MM/yyyy"
                    value={this.state.startDateTime}
                    onChange={(startDateTime) => {
                      this.handleDateTimePickerValidation(
                        startDateTime,
                        sessionDuration
                      );
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid item lg={5}>
                  <TimePicker
                    label="Appointment time"
                    value={this.state.startDateTime}
                    minutesStep={5}
                    onChange={(startDateTime) => {
                      this.handleDateTimePickerValidation(
                        startDateTime,
                        sessionDuration
                      );
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                {!this.state.isValidDateTimeSlot && (
                  <Alert severity="error" sx={{ ml: 1, mt: 2 }}>
                    Consultant is not available during the date/time. Please
                    select valid date/time.
                  </Alert>
                )}
              </Grid>
            </LocalizationProvider>
            <Stack spacing={3} mt={3} pb={10}>
              <FormLabel>Notes/Message to Doctor</FormLabel>
              <TextareaAutosize
                aria-label="Notes/Message to Doctor"
                minRows={2}
                style={{ marginTop: 0, fontSize: 15, height: 100 }}
                onChange={(event) => {
                  this.setState({
                    description: event.target.value,
                  });
                }}
              />
            </Stack>
            <div className="footer-action">
              <div className="footer-buttons">
                <Button
                  autoFocus
                  variant="contained"
                  color="error"
                  sx={{ mr: 2 }}
                  endIcon={<XCircle />}
                  onClick={() => {
                    this.setState({ addAppointmentDrawer: false });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  endIcon={<Save />}
                  disabled={!this.state.isValidDateTimeSlot}
                  onClick={() => {
                    this.bookAnAppointment();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Container>
        </Drawer>
      </React.Fragment>
    );
  }
}

// Todo check if needed
// Usage in components
// dateCellWrapper: DateCell,
// const DateCell = ({ range, value, children }) => {
//   const now = new Date();
//   now.setHours(0, 0, 0, 0);

//   return (
//    { <div className={value < now ? "date-in-past" : ""}>{children}</div>}
//   );
// };

// eventPropGetter={reactBigCalendarEventStyle}
// const reactBigCalendarEventStyle = (event) => {
//   const style = {
//     //        color: "red",
//   };
//   return {
//     style: style,
//   };
// };
