import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import "./menu-items.css";
import { Calendar2PlusFill, HouseDoorFill, List } from "react-bootstrap-icons";

const referenceCode = localStorage.getItem("referenceCode") || null;
export const mainListItems = (
  <React.Fragment>
    <ListItemButton href={"/home/" + referenceCode}>
      <ListItemIcon>
        <HouseDoorFill />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItemButton>
    <ListItemButton href={"/appointment-list/" + referenceCode}>
      <ListItemIcon>
        <PlaylistAddCheckIcon fontSize="large"/>
      </ListItemIcon>
      <ListItemText primary="Appointment List" />
    </ListItemButton>
    <ListItemButton href="/book-an-appointment">
      <ListItemIcon>
        <Calendar2PlusFill />
      </ListItemIcon>
      <ListItemText primary="New Appointment" />
    </ListItemButton>
    {/* <ListItemButton href="/book-appointment" title="Old Appointment Flow">
      <ListItemIcon>
        <Calendar2PlusFill />
      </ListItemIcon>
      <ListItemText primary="Old Appointment Flow" />
    </ListItemButton> */}
  </React.Fragment>
);
