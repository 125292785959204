import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Alert,
  Box,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  capitalize,
  Collapse,
} from "@mui/material";
import moment from "moment";
import React from "react";
import {
  PersonCircle,
  PlusSquareFill,
  Hospital,
  Save,
  XCircle,
  Calendar2Week,
  CalendarPlusFill,
} from "react-bootstrap-icons";
import { UserContext } from "../../components/layout/Layout";
import Utils from "../../utils/utils";
import _ from "lodash";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import Spinner from "../../components/spinner/spinner";

export default class RescheduleAnAppointment extends React.Component<any, any> {
  static contextType = UserContext;

  consultantTimings =
    this.props.apptDetails.modeOfAdmission === "OP"
      ? this.props.apptDetails.doctor.opTimings
      : this.props.apptDetails.doctor.teleTimings;

  constructor(props: any) {
    super(props);
    const startDate = moment(this.props.apptDetails.start).format("DD-MM-YYYY");
    const startDateTime = moment(
      startDate + " " + this.props.apptDetails.startTime,
      "DD-MM-YYYY HH:mm"
    );
    this.state = {
      origStartDateTime: startDateTime,
      startDateTime: startDateTime,
      isLoading: false,
      saveError: false,
      isValidDateTimeSlot: true,
      consultantDetails: null,
      isSlotAvailable: true,
    };
  }

  validate() {
    const selectedDate = new Date(this.state.startDateTime);
    if (this.checkDateIsHoliday(selectedDate)) {
      this.setState({ isHoliday: true });
      setTimeout(() => {
        this.setState({ isHoliday: false });
      }, 2000);
    } else if (moment(selectedDate).isBefore()) {
      this.setState({ isExpiredDate: true });
      setTimeout(() => {
        this.setState({ isExpiredDate: false });
      }, 2000);
    } else {
      const availablity =
        this.consultantTimings[moment(selectedDate).format("ddd")];
      let calendarTime = parseInt(moment(selectedDate).format("HHmm"));
      const amStart = parseInt(availablity.amStart.split(":").join(""));
      const amEnd = parseInt(availablity.amEnd.split(":").join(""));
      const pmStart = parseInt(availablity.pmStart.split(":").join(""));
      const pmEnd = parseInt(availablity.pmEnd.split(":").join(""));

      if (
        (calendarTime >= amStart && calendarTime <= amEnd) ||
        (calendarTime >= pmStart && calendarTime <= pmEnd)
      ) {
        this.setState({
          isValidDateTimeSlot: true,
        });
      } else {
        this.setState({ isSlotAvailable: false });
        setTimeout(() => {
          this.setState({ isSlotAvailable: true });
        }, 2000);
      }
    }
  }
  checkDateIsHoliday(date: Date) {
    let isHoliday = false;
    if (_.isEmpty(this.consultantTimings)) {
      isHoliday = true;
    } else {
      let daySlots = {};
      if (date.getDay() === 0) {
        daySlots = this.consultantTimings.Sun;
      } else if (date.getDay() === 1) {
        daySlots = this.consultantTimings.Mon;
      } else if (date.getDay() === 2) {
        daySlots = this.consultantTimings.Tue;
      } else if (date.getDay() === 3) {
        daySlots = this.consultantTimings.Wed;
      } else if (date.getDay() === 4) {
        daySlots = this.consultantTimings.Thu;
      } else if (date.getDay() === 5) {
        daySlots = this.consultantTimings.Fri;
      } else {
        daySlots = this.consultantTimings.Sat;
      }
      isHoliday = Object.values(daySlots).every((x) => x === null || x === "");
    }
    const currentDate = moment(date).format("DD/MMM/YYYY");
    if (
      this.props.apptDetails.doctor.holidayOne !== null &&
      this.props.apptDetails.doctor.holidayOne === currentDate
    ) {
      isHoliday = true;
    } else if (
      this.props.apptDetails.doctor.holidayTwo !== null &&
      this.props.apptDetails.doctor.holidayTwo === currentDate
    ) {
      isHoliday = true;
    } else if (
      this.props.apptDetails.doctor.holidayThree !== null &&
      this.props.apptDetails.doctor.holidayThree === currentDate
    ) {
      isHoliday = true;
    } else if (
      this.props.apptDetails.doctor.holidayFour !== null &&
      this.props.apptDetails.doctor.holidayFour === currentDate
    ) {
      isHoliday = true;
    } else if (
      this.props.apptDetails.doctor.holidayFive !== null &&
      this.props.apptDetails.doctor.holidayFive === currentDate
    ) {
      isHoliday = true;
    }
    return isHoliday;
  }

  onRescheduleAppointment() {
    this.validate();
    if (this.state.isValidDateTimeSlot) {
      const appointmentDetails = this.props.apptDetails;
      delete appointmentDetails["doctor"];
      appointmentDetails["start"] = this.state.startDateTime;
      appointmentDetails["end"] = this.state.endDateTime;
      appointmentDetails["startTime"] = moment(this.state.startDateTime).format(
        "HH:mm"
      );
      appointmentDetails["endTime"] = moment(this.state.endDateTime).format(
        "HH:mm"
      );
      axios
        .put(
          `${Utils.PRIISM_API_ENDPOINT}/bookings/reschedule/${this.context["heid"]}/${appointmentDetails.apptId}/`,
          appointmentDetails
        )
        .then((res) => {
          this.setState({ isLoading: false });
          this.props.onRescheduleSuccess();
        })
        .catch((error) => {
          this.setState({ isLoading: false, saveError: true });
          console.error(error);
        });
    } else {
      this.setState({ isLoading: false });
    }
  }
  render() {
    const patientName =
      this.context["first_name"] + " " + this.context["last_name"];

    const appointmentType =
      this.props.apptDetails.modeOfAdmission === "TELE"
        ? "Online"
        : "Personal visit";

    let doctorName = capitalize(this.props.apptDetails.doctor.firstName);
    if (this.props.apptDetails.doctor.lastName) {
      doctorName += " " + this.props.apptDetails.doctor.lastName;
    }
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={true}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          sx={{ backgroundColor: "#1976d2", color: "white" }}
          id="responsive-dialog-title"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", mr: 2 }}>
              <Calendar2Week size={25} />
            </Box>
            Reschedule appointment
          </Box>
        </DialogTitle>
        <DialogContent>
          {this.state.isLoading && <Spinner />}
          <Grid container sx={{ mt: 2 }}>
            <Grid item lg={3}>
              <ListItem>
                <ListItemAvatar sx={{ minWidth: "48px" }}>
                  <PersonCircle color="#1976d2" size={30} />
                </ListItemAvatar>
                <ListItemText
                  primary={doctorName}
                  secondary={
                    capitalize(this.props.apptDetails.doctor.role) || ""
                  }
                />
              </ListItem>
            </Grid>
            <Grid item lg={3}>
              <ListItem sx={{ mt: 1 }}>
                <ListItemAvatar sx={{ minWidth: "48px" }}>
                  <PlusSquareFill color="#1976d2" size={30} />
                </ListItemAvatar>
                <ListItemText primary={appointmentType} />
              </ListItem>
            </Grid>
            {this.props.apptDetails?.locName && (
              <Grid item lg={3}>
                <ListItem sx={{ mt: 1 }}>
                  <ListItemAvatar sx={{ minWidth: "48px" }}>
                    <Hospital color="#1976d2" size={30} />
                  </ListItemAvatar>
                  <ListItemText primary={this.props.apptDetails?.locName} />
                </ListItem>
              </Grid>
            )}
            <Grid item lg={3}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar {...Utils.stringAvatar(patientName)} />
                </ListItemAvatar>
                <ListItemText
                  primary={patientName}
                  secondary={this.context["patient_no"]}
                />
              </ListItem>
            </Grid>
            {!this.props.apptDetails?.locName && (
              <Grid item lg={3}>
                <span></span>
              </Grid>
            )}
            <Grid item lg={3}>
              <ListItem>
                <ListItemAvatar>
                  <CalendarPlusFill color="#1976d2" size={30} />
                </ListItemAvatar>
                <ListItemText
                  primary={moment(this.props.apptDetails.start).format(
                    "DD/MM/YYYY"
                  )}
                  secondary={moment(this.props.apptDetails.start).format(
                    "HH:mm"
                  )}
                />
              </ListItem>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item lg={3} sx={{ pt: 2, pl: 2 }}>
                <DesktopDatePicker
                  label="New appointment date"
                  inputFormat="dd/MM/yyyy"
                  value={this.state.startDateTime}
                  onChange={(startDateTime) => {
                    this.setState({
                      startDateTime: startDateTime,
                      endDateTime: moment(startDateTime).add(30, "minutes"),
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item lg={3} sx={{ pt: 2, pl: 2 }}>
                <TimePicker
                  label="New appointment time"
                  value={this.state.startDateTime}
                  minutesStep={5}
                  onChange={(startDateTime) => {
                    this.setState({ startDateTime: startDateTime });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              {!this.state.isValidDateTimeSlot && (
                <Alert severity="error" sx={{ ml: 1, mt: 2 }}>
                  Consultant is not available during the date/time. Please
                  select valid date/time.
                </Alert>
              )}
              {this.state.saveError && (
                <Alert sx={{ ml: 1, mt: 2 }} severity="error">
                  Error in saving the date. Please try again.
                </Alert>
              )}
              <Collapse in={this.state.isHoliday}>
                <Alert sx={{ ml: 1, mt: 2 }} severity="error">
                  Selected day is an holiday.
                </Alert>
              </Collapse>
              <Collapse in={this.state.isExpiredDate}>
                <Alert sx={{ ml: 1, mt: 2 }} severity="error">
                  Selected day is expired.
                </Alert>
              </Collapse>
              <Collapse in={this.state.isExpiredSlot}>
                <Alert sx={{ ml: 1, mt: 2 }} severity="error">
                  Selected slot is expired.
                </Alert>
              </Collapse>
              <Collapse in={!this.state.isSlotAvailable}>
                <Alert sx={{ ml: 1, mt: 2 }} severity="error">
                  Selected slot is not available.
                </Alert>
              </Collapse>
            </LocalizationProvider>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            color="error"
            endIcon={<XCircle />}
            onClick={() => {
              this.props.onDiscardReschedule();
            }}
          >
            Discard
          </Button>
          <Button
            variant="contained"
            autoFocus
            disabled={this.state.origStartDateTime === this.state.startDateTime}
            endIcon={<Save />}
            onClick={() => {
              this.setState({ isValidDateTimeSlot: false, isLoading: true });
              setTimeout(() => {
                this.onRescheduleAppointment();
              }, 2000);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
