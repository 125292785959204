import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import AppointmentDetails from "./appointment-details/AppointmentDetails";
import CalendarDetails from "./calendar-details/CalendarDetails";
import SummaryDetails from "./summary-details/SummaryDetails";
import { Alert, Collapse } from "@mui/material";
import axios from "axios";
import Utils from "../../utils/utils";
import "./BookAnAppointment.css";
import Spinner from "../../components/spinner/spinner";
import {
  ArrowLeftCircle,
  ArrowRightCircle,
  HouseDoor,
} from "react-bootstrap-icons";
import { UserContext } from "../../components/layout/Layout";
import RazorPayUtils from "../../utils/razorpay-utils";

const steps = ["Details", "Schedule", "Summary"];

type Props = {};
type BookAnAppointmentState = {
  activeStep: number;
  alertIsOpen: boolean;
  locations: any;
  consultants: any;
  appointments: any;
  appointmentType: string;
  consultationType: string;
  centre: string;
  consultant: string;
  consentDialogIsOpen: boolean;
  consentIsAgreed: boolean;
  appointmentDetails: any;
  isLoading: boolean;
};

export default class BookAnAppointment extends React.Component<
  Props,
  BookAnAppointmentState
> {
  static contextType = UserContext;
  constructor(props: any) {
    super(props);
    this.state = {
      activeStep: 0,
      alertIsOpen: false,
      locations: [],
      consultants: [],
      appointments: [],
      appointmentType: "",
      consultationType: "",
      centre: "",
      consultant: "",
      consentDialogIsOpen: false,
      consentIsAgreed: false,
      appointmentDetails: "",
      isLoading: false,
    };
  }

  componentDidMount(): void {
    axios
      .get(
        `${Utils.PRIISM_API_ENDPOINT}/appt_bookings/locationlist/${this.context["heid"]}/`
      )
      .then((res) => {
        const locations = res.data;
        this.setState({ locations });
        if (locations && locations.length === 0) {
          this.getConsultants();
        } else if (locations && locations.length === 1) {
          this.setState({ centre: locations[0].locId });
          this.getConsultants();
        }
      });
  }
  getConsultants() {
    this.setState({ isLoading: true });
    axios
      .get(
        `${Utils.PRIISM_API_ENDPOINT}/appt_bookings/doctorlist/${this.context["heid"]}/${this.state.centre}/`
      )
      .then((res) => {
        let consultants: string | any[] = [];
        if (res.data) {
          consultants = consultants.concat(res.data.psychiatrist || []);
          consultants = consultants.concat(res.data.socialworker || []);
        }
        this.setState({ consultants: consultants, isLoading: false });
        if (consultants && consultants.length === 1) {
          this.setState({ consultant: consultants[0].userId });
        }
      });
  }

  showAlert(stepIsValid: boolean) {
    if (stepIsValid) {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    } else {
      this.setState({ alertIsOpen: true });
      setTimeout(() => {
        this.setState({ alertIsOpen: false });
      }, 2000);
    }
  }

  displayRazorpay() {
    const options = {
      key: RazorPayUtils.KEY_ID,
      key_secret: RazorPayUtils.RAZORPAY_PUBLIC_KEY,
      amount: 50000,
      currency: "INR",
      receipt: "receipt#1",
      theme: {
        color: "#3399cc",
      },
      prefill: {
        name: this.context["first_name"] + " " + this.context["last_name"],
        email: this.context["email"],
        contact: this.context["mobile"],
      },
    };
    var instance = (window as any).Razorpay(options);
    instance.open();
  }

  getLastStepButtion() {
    const consultantData = this.state.consultants?.find((consultant: any) => {
      return consultant.userId === this.state.consultant;
    });
    if (
      consultantData &&
      (consultantData.firstTimeConsultantFee ||
        consultantData.followupConsultantFee)
    ) {
      return (
        <Button
          variant="contained"
          className="footer-button"
          onClick={() => {
            this.displayRazorpay();
          }}
          sx={{ mt: 3, ml: 1 }}
        >
          <img
            width="80px"
            height="28px"
            src="https://dashboard-activation.s3.amazonaws.com/org_100000razorpay/main_logo/phpAJgHea"
          ></img>
          <span className="paynow-text">
            Pay now Rs.{" "}
            {this.state.consultationType === "FIRST"
              ? consultantData?.firstTimeConsultantFee
              : consultantData?.followupConsultantFee}
          </span>
        </Button>
      );
    } else {
      return (
        <Button
          startIcon={<HouseDoor />}
          variant="contained"
          className="footer-button"
          href="/"
          sx={{ mt: 3, ml: 1 }}
        >
          Home
        </Button>
      );
    }
  }

  render() {
    return (
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Stepper activeStep={this.state.activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {this.state.activeStep === steps.length ? (
              <div> Refresh</div>
            ) : (
              <React.Fragment>
                {this.state.isLoading && <Spinner />}
                <Collapse in={this.state.alertIsOpen}>
                  <Alert severity="error">
                    This is an error alert — Please fill all fields!
                  </Alert>
                </Collapse>
                {this.state.activeStep === 0 && (
                  <AppointmentDetails
                    {...this.state}
                    setAppointmentType={(response: any) => {
                      if (response) {
                        this.setState({
                          appointmentType: response.appointmentType,
                          consentDialogIsOpen: response.consentDialogIsOpen,
                          centre: "",
                          consultant: "",
                          consultationType: "",
                        });
                      }
                    }}
                    setCity={(response: any) => {
                      if (response) {
                        this.setState({
                          centre: response,
                          consultant: "",
                        });
                        this.setState({ isLoading: true });
                        setTimeout(() => {
                          this.getConsultants();
                        }, 250);
                      }
                    }}
                    setConsultant={(response: any) => {
                      this.setState({
                        consultant: response,
                      });
                    }}
                    onConsentAgreed={() => {
                      this.setState({
                        consentIsAgreed: true,
                      });
                      this.getConsultants();
                    }}
                    onConsentDialog={(response: any) => {
                      this.setState({
                        consentDialogIsOpen: response,
                      });
                    }}
                    setConsultationType={(response: any) => {
                      this.setState({
                        consultationType: response,
                      });
                    }}
                  />
                )}
                {this.state.activeStep === 1 && (
                  <CalendarDetails
                    {...this.state}
                    setOutPatientList={(outPatientList) => {
                      this.setState({
                        appointments: outPatientList,
                      });
                    }}
                    onBookingAppointmentSuccess={(response: any) => {
                      this.setState({
                        appointmentDetails: response,
                        activeStep: this.state.activeStep + 1,
                      });
                    }}
                  />
                )}
                {this.state.activeStep === 2 && (
                  <SummaryDetails {...this.state} />
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {this.state.activeStep === 1 && (
                    <Button
                      variant="contained"
                      className="footer-button"
                      startIcon={<ArrowLeftCircle />}
                      onClick={() => {
                        this.setState({
                          activeStep: this.state.activeStep - 1,
                        });
                      }}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      Back
                    </Button>
                  )}
                  {this.state.activeStep === 0 && (
                    <Button
                      className="footer-button"
                      variant="contained"
                      endIcon={<ArrowRightCircle />}
                      onClick={() => {
                        if (this.state.activeStep === 0) {
                          let stepIsValid = false;
                          if (this.state.appointmentType == "TELE") {
                            stepIsValid =
                              this.state.consultationType != "" &&
                              this.state.consultant != "" &&
                              this.state.consultationType != "";
                          } else {
                            stepIsValid =
                              this.state.centre != "" &&
                              this.state.consultant != "" &&
                              this.state.consultationType != "";
                          }
                          this.showAlert(stepIsValid);
                        } else if (this.state.activeStep === 1) {
                          let stepIsValid = false;
                          this.showAlert(stepIsValid);
                        }
                      }}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      Next
                    </Button>
                  )}
                  {this.state.activeStep === 2 && this.getLastStepButtion()}
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </Container>
    );
  }
}
