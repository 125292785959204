
import React, { useState, useEffect } from 'react';
import "./App.css";
import "./styles/global.css";
import SignIn from "./pages/sign-in/SignIn";
import SignUp from "./pages/sign-up/SignUp";
import { Navigate, Route, Routes } from "react-router-dom";
import AppointmentsTable from "./pages/appointments-table/AppointmentsTable";
import BookAnAppointment from "./pages/book-an-appointment/BookAnAppointment";
import BookAppointment from './pages/appointment/BookAppointment';
import Home from './pages/home/Home';
import Profile from './pages/profile/Profile';
import Layout from "./components/layout/Layout";
import * as _ from "lodash";
import Info from "./components/info/Info";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref,
) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionLeft(props: any) {
	return <Slide {...props} direction="left" />;
}

const App = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const referenceCode = new URLSearchParams(location.search).get('referenceCode');

	const isAuthenticatedStore = localStorage.getItem("isAuthenticated") || false;
	const patientDetailsStore = localStorage.getItem("patientDetails") || null;

	const [isAuthenticated, setAuthenticated] = useState(isAuthenticatedStore);
	const [patientDetails, setpatientDetails] = useState(patientDetailsStore);

	const [isUnAuthorized, setIsUnAuthorized] = useState(JSON.parse(
		localStorage.getItem("isUnAuthorized") || null
	));

	useEffect(() => {
		if (referenceCode)
			localStorage.setItem("referenceCode", referenceCode ?? null);
	}, [referenceCode])

	const onLoginSuccess = (response: any) => {
		setAuthenticated(true);
		localStorage.setItem("authToken", response?.access_token ?? null);
		delete response.access_token;
		setpatientDetails(response);
		localStorage.setItem("patientDetails", JSON.stringify(response));
		localStorage.setItem("isAuthenticated", JSON.stringify(true));
	};

	const handleClose = (event: any) => {
		localStorage.setItem("isUnAuthorized", JSON.stringify(false));
		setIsUnAuthorized(Boolean(false));
	};

	return (
		<div className="App">
			{isUnAuthorized &&
				<Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} TransitionComponent={TransitionLeft} open={isUnAuthorized} autoHideDuration={6000} onClose={handleClose}>
					<Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
						Signature verification failed. Please relogin again.
					</Alert>
				</Snackbar>
			}
			<Routes>
				<Route
					path={"/"}
					element={
						(isAuthenticated === true) ? (
							<Navigate to={`/appointment-list/${referenceCode}`} />
							// <Layout children={<AppointmentsTable />}></Layout>
						) : (
							<Navigate to={`/home/${referenceCode}`} />
						)
					}
				/>
				<Route
					path="/appointment-list/:referenceCode"
					element={
						<Layout children={<AppointmentsTable />}></Layout>
					}
				/>
				<Route
					path="/home/:referenceCode"
					element={
						<Layout children={<Home />}></Layout>
					}
				/>
				<Route
					path="/profile/:heCode/:userId"
					element={
						<Layout children={<Profile />}></Layout>
					}
				/>
				<Route
					path={`/sign-in/${referenceCode}`}
					element={
						isAuthenticated ? (
							<Navigate to="/" />
						) : (<Layout children={
							<SignIn
								onLoginSuccess={(response: any) => {
									onLoginSuccess(response);
									// window.location.href = "/";
								}}
							/>} />
						)
					}
				/>
				<Route
					path="/sign-in/:referenceCode"
					element={
						<Layout children={<SignIn
							onLoginSuccess={(response: any) => {
								onLoginSuccess(response);
								// window.location.href = "/";
							}}
						/>}></Layout>
					}
				/>
				<Route
					path="/book-an-appointment"
					element={
						_.isEmpty(isAuthenticated) ? (
							<Navigate to={`/home/${referenceCode}`} />
						) : (
							<Layout children={<BookAppointment onLoginSuccess={{}} />}></Layout>
						)
					}
				/>
				<Route
					path="/reschedule-appointment/:heId/:apptId"
					element={
						_.isEmpty(isAuthenticated) ? (
							<Navigate to={`/home/${referenceCode}`} />
						) : (
							<Layout children={<BookAppointment onLoginSuccess={{}} />}></Layout>
						)
					}
				/>
				<Route
					path="/book-an-appointment/:heCode"
					element={
						<Layout children={<BookAppointment onLoginSuccess={onLoginSuccess} />}></Layout>
					}
				/>
				<Route
					path="/book-an-appointment/:heCode/:userId"
					element={
						<Layout children={<BookAppointment onLoginSuccess={onLoginSuccess} />}></Layout>
					}
				/>
				<Route
					path="/book-appointment"
					element={
						_.isEmpty(isAuthenticated) ? (
							<Navigate to={`/home/${referenceCode}`} />
						) : (
							<Layout children={<BookAnAppointment />}></Layout>
						)
					}
				/>
				<Route
					path="/sign-up/:referenceCode"
					element={isAuthenticated ? <Navigate to={"/home/" + referenceCode} /> :
						<Layout children={<SignUp />}></Layout>}
				/>
			</Routes>
		</div>
	);
};
export default App;
