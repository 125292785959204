import { PdfmakeConfig } from "./pdfmake-config";
import moment from "moment";

export class DefaultHeaderConfig {
	public pdfmakeConfig = new PdfmakeConfig();
	public languageContent: any;
	private temp = null;

	public defaultHeader(PdfmakeConfig) {}

	public getLines(PdfmakeConfig, sides, noOfLines) {
		let table = {
			widths: [240, 20, 240], // total 500 width
			heights: [0, 0, 0],
			body: this.setLines(PdfmakeConfig, sides),
		};
		return table;
	}

	public setLines(PdfmakeConfig, sides) {
		return [
			{ border: PdfmakeConfig.getBorder(sides), text: "", marginTop: 0 },
			{ border: PdfmakeConfig.getBorder(""), text: "", marginTop: 0 },
			{ border: PdfmakeConfig.getBorder(sides), text: "", marginTop: 0 },
		];
	}

	public getHeader(header, PdfmakeConfig, clinicDetails, userDetails) {
		let userName = userDetails?.firstName;
		if (userDetails) {
			if (userDetails.lastName) userName += " " + userDetails.lastName;
			userDetails.medicalCouncilName2 = userDetails.medicalCouncilName2
				? userDetails.medicalCouncilName2
				: "";
		}
		clinicDetails.address = clinicDetails.address ? clinicDetails.address : "";
		clinicDetails.city = clinicDetails.city ? clinicDetails.city : "";
		let address = "";
		address += clinicDetails.address + ", " + clinicDetails.city;
		let phoneNo = "";
		phoneNo += clinicDetails.phone1 ? clinicDetails.phone1 : "";
		if (clinicDetails.phone2) {
			if (phoneNo) phoneNo += "/ ";
			phoneNo += clinicDetails.phone2 ? clinicDetails.phone2 : "";
		}
		let table = {
			widths: ["auto", "*", "auto"],
			body: [
				[
					{ image: "header", width: 100, height: 100, alignment: "left" },
					{
						border: PdfmakeConfig.getBorder(""),
						text: clinicDetails.name,
						alignment: "center",
						bold: true,
						marginTop: 5,
						fontSize: 18,
						marginBottom: 5,
						colSpan: 2,
					},
					{},
				],
				[
					{
						border: PdfmakeConfig.getBorder(""),
						text: "Doctor " + " : " + userDetails.firstName,
						bold: false,
						fontSize: 10,
					},
					{
						border: PdfmakeConfig.getBorder(""),
						text: "Address " + " : " + address,
						bold: false,
						fontSize: 10,
						colSpan: 2,
					},
					{},
				],
				[
					{
						border: PdfmakeConfig.getBorder(""),
						text:
							"Medical Council No " + ": " + userDetails?.medicalCouncilName2,
						bold: false,
						fontSize: 10,
					},
					{
						border: PdfmakeConfig.getBorder(""),
						text: "Phone no " + ": " + clinicDetails.phone1,
						bold: false,
						fontSize: 10,
					},
					{
						border: PdfmakeConfig.getBorder(""),
						text: "Qualification " + " : " + userDetails.qualification,
						bold: false,
						fontSize: 10,
					},
				],
			],
		};
		PdfmakeConfig.content.push({
			style: "tableHeader",
			table: table,
			layout: "noBorders",
		});
	}

	public getSignatureData(PdfmakeConfig) {
		PdfmakeConfig.content.push({
			image: "signature",
			width: 150,
			height: 100,
			alignment: "right",
			marginTop: 10,
		});
	}

	public getPatientInfo(pdfmakeConfig, patientInfo, visitDetails) {
		let patientName = patientInfo.first_name;
		if (patientInfo.lastName) patientName += " " + patientInfo.last_name;
		let visitDate = moment();
		if (visitDetails && visitDetails?.visitDate)
			visitDate = moment(visitDetails?.visitDate, "YYYY-MM-DD");
		let table = {
			widths: [85, 75, 75, 75, 75, 75], // total 500 width
			heights: [0, 0, 0, 0, 0, 0],
			body: [
				[
					{
						border: pdfmakeConfig.getBorder("T"),
						text: "Name" + " : ",
						fontSize: 10,
					},
					{
						border: pdfmakeConfig.getBorder("T"),
						text: patientName,
						bold: true,
						fontSize: 10,
					},
					{ border: pdfmakeConfig.getBorder("T"), text: "", fontSize: 10 },
					{
						border: pdfmakeConfig.getBorder("T"),
						text: "",
						bold: true,
						fontSize: 10,
					},
					{
						border: pdfmakeConfig.getBorder("T"),
						text: "Date : ",
						alignment: "right",
						fontSize: 10,
					},
					{
						border: pdfmakeConfig.getBorder("T"),
						text: moment(visitDate).format("DD-MM-YYYY"),
						bold: true,
						alignment: "left",
						fontSize: 10,
					},
				],
				[
					{
						border: pdfmakeConfig.getBorder(""),
						text: "Age/Gender : ",
						fontSize: 10,
					},
					{
						border: pdfmakeConfig.getBorder(""),
						text: patientInfo.age + "/" + patientInfo.sex,
						bold: true,
						fontSize: 10,
					},
					{ border: pdfmakeConfig.getBorder(""), text: "", fontSize: 10 },
					{
						border: pdfmakeConfig.getBorder(""),
						text: "",
						bold: true,
						fontSize: 10,
					},
					{
						border: pdfmakeConfig.getBorder(""),
						text: "OP no : ",
						alignment: "right",
						fontSize: 10,
					},
					{
						border: pdfmakeConfig.getBorder(""),
						text: patientInfo.patient_no,
						bold: true,
						alignment: "left",
						fontSize: 10,
					},
				],
				[
					{ border: pdfmakeConfig.getBorder("B"), text: "", colSpan: 6 },
					{},
					{},
					{},
					{},
					{},
				],
			],
		};
		pdfmakeConfig.content.push({ style: "tableHeader", table: table });
	}

	public getPrescriptionData(pdfmakeConfig, prescriptionList) {
		var tableHeaders = [
			{ name: "Drug", headBorder: "LTB" },
			{ name: "Brand", headBorder: "LTB" },
			{ name: "Dosage", headBorder: "LTB" },
			{ name: "Schedule", headBorder: "LTB" },
			{ name: "Duration", headBorder: "LTB" },
			{ name: "Remarks", headBorder: "LTBR" },
		];
		let table = {
			widths: [130, "auto", "auto", "auto", "auto", "*"], // total 500 width
			heights: [0, 0, 0, 0, 0, 0],
			marginTop: 40,
			body: this.setTableContent(
				pdfmakeConfig,
				tableHeaders,
				prescriptionList,
				"prescription"
			),
		};
		pdfmakeConfig.content.push({ style: "tableContent", table: table });
		this.setPrescriptionRemarks(pdfmakeConfig, prescriptionList);
	}

	public setPrescriptionRemarks(pdfmakeConfig, precList) {
		var remarks = [];
		let drugs = [];
		precList.forEach(function (value) {
			if (drugs.indexOf(value.drugName) == -1) {
				if (value.preFixedRemarks) {
					remarks.push({
						text: [
							{
								border: pdfmakeConfig.getBorder("B"),
								text: value.drugName + " : ",
								bold: true,
								fontSize: 10,
							},
							{
								border: pdfmakeConfig.getBorder(""),
								text: value.preFixedRemarks,
								fontSize: 10,
							},
						],
						marginLeft: 15,
					});
				}
				drugs.push(value.drugName);
			}
		});
		if (remarks.length > 0) {
			pdfmakeConfig.content.push({
				border: pdfmakeConfig.getBorder(""),
				text: " ",
			});
			pdfmakeConfig.content.push({
				border: pdfmakeConfig.getBorder("B"),
				text: this.languageContent.additional_remarks,
				bold: true,
				fontSize: 10,
			});
			remarks.push({ border: pdfmakeConfig.getBorder(""), text: " " });
			pdfmakeConfig.content = [...pdfmakeConfig.content, remarks];
		}
	}

	public setTableHeaders(pdfmakeConfig, tableHeaders) {
		let tHead = [];
		tableHeaders.forEach(function (heading, hIndex) {
			tHead.push({
				border: pdfmakeConfig.getBorder(heading.headBorder),
				text: heading.name,
				bold: true,
				colSpan: heading.colSpan,
				fontSize: 10,
				fillColor: "#CCCCCC",
			});
		});
		return tHead;
	}

	public setTableContent(pdfmakeConfig, tableHeaders, list, type) {
		var bodyData = [];

		bodyData.push(this.setTableHeaders(pdfmakeConfig, tableHeaders));
		switch (type) {
			case "prescription":
				list.forEach(function (item, index) {
					let schedule = item.schedule;
					/*if (item.drugScheduleType == 'SOS')
					schedule = 'SOS';
				else {
					schedule = item.drugSchedule1 + ' - ' + item.drugSchedule2 + ' - ' + item.drugSchedule3;
					if (item.drugScheduleType == 'QID') {
						schedule += ' - ' + item.drugSchedule4;
					}
				}*/
					let duration = item.duration + " " + item.durationUom;
					var dataRow = [
						{
							border: pdfmakeConfig.getBorder("LB"),
							text: item.drugName,
							fontSize: 10,
						},
						{
							border: pdfmakeConfig.getBorder("LB"),
							text: item.brandName,
							fontSize: 10,
						},
						{
							border: pdfmakeConfig.getBorder("LB"),
							text: item.dosage,
							fontSize: 10,
						},
						{
							border: pdfmakeConfig.getBorder("LB"),
							text: schedule,
							fontSize: 10,
						},
						{
							border: pdfmakeConfig.getBorder("LB"),
							text: duration,
							fontSize: 10,
						},
						{
							border: pdfmakeConfig.getBorder("LBR"),
							text: item.remarks,
							fontSize: 10,
						},
					];

					bodyData.push(dataRow);
				});
				break;

			default:
				list.forEach(function (item, index) {
					var dataRow = [];
					if (type == "Symptoms")
						var duration = item.duration + " " + item.durationUom;
					tableHeaders.forEach(function (element, rIndex) {
						dataRow.push({
							border: pdfmakeConfig.getBorder(element.dataBorder),
							text:
								element.fieldName != "duration"
									? item[element.fieldName]
									: duration,
							colSpan: element.colSpan,
							fontSize: 10,
						});
					});
					bodyData.push(dataRow);
				});
		}
		return this.getRowsWithColSpan(bodyData, tableHeaders);
	}

	public getRowsWithColSpan(list, tableHeaders) {
		let rowData = [];
		list.forEach((element) => {
			for (let j = 0; j < tableHeaders.length; j++) {
				if (element[j].colSpan) {
					for (let i = 1; i < element[j].colSpan; i++) {
						element.splice(i, 0, {});
					}
				}
			}
			rowData.push(element);
		});
		return rowData;
	}

	public getPrescriptionNotes(pdfmakeConfig, prescription) {
		let table = {
			widths: ["auto", "auto"], // total 500 width
			heights: [0, 0],
			body: [
				[
					{
						border: pdfmakeConfig.getBorder(""),
						text: "Prescription Notes : ",
						fontSize: 11,
						bold: true,
					},
					{
						border: pdfmakeConfig.getBorder(""),
						text: prescription,
						fontSize: 10,
					},
				],
			],
		};
		pdfmakeConfig.content.push({ style: "tableContent", table: table });
	}

	public getNextVisitDate(pdfmakeConfig, followupDate) {
		let len = followupDate.length;
		// if (len > 11) {
		// 	followupDate = moment(followupDate).format("DD-MMM-YYYY");
		// }
		let table = {
			widths: ["auto", "auto"],
			heights: [0, 0],
			body: [
				[
					{
						border: pdfmakeConfig.getBorder(""),
						text: "Next Visit Date : ",
						fontSize: 10,
						bold: true,
					},
					{
						border: pdfmakeConfig.getBorder(""),
						text: followupDate,
						fontSize: 10,
					},
				],
			],
		};
		pdfmakeConfig.content.push({ style: "tableContent", table: table });
	}

	public getOrderedInvestigations(pdfmakeConfig, orderedInvsList) {
		var tableHeaders = [
			{
				name: "Ordered Investigations",
				headBorder: "LTBR",
				dataBorder: "LTBR",
				fieldName: "nameOrdered",
			},
		];
		let table = {
			widths: [505], // total 500 width
			heights: [0],
			body: this.setTableContent(
				pdfmakeConfig,
				tableHeaders,
				orderedInvsList,
				"orderedInvestigations"
			),
		};
		pdfmakeConfig.content.push({ text: " " });
		pdfmakeConfig.content.push({ style: "tableContent", table: table });
		pdfmakeConfig.content.push({ text: " " });
	}
}
